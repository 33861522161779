import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import ColumnAlternating from '../../../components/columnAlternating'
import Cta from '../../../components/cta'
import ColumnAlternatingButton from '../../../components/columnAlternatingButton'
import TextOverImage from '../../../components/columnTextOverImage'
import ModalImage from '../../../components/modalImage'
import Card from '../../../components/card'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        <section className="bg-blue-green-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.hybridmodel.title}
                ctadescription={post.frontmatter.hybridmodel.description}
                ctalink={post.frontmatter.hybridmodel.link}
                ctalinktext={post.frontmatter.hybridmodel.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>

        <section className="bg-white">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <TextOverImage
                title={post.frontmatter.security.title}
                subtitle={post.frontmatter.security.subtitle}
                description={post.frontmatter.security.description}
                imageUrl={post.frontmatter.security.image.childImageSharp.fluid}
                imageAltText={post.frontmatter.security.alttext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => {
                    return (
                      <Fragment key={index}>
                        <ColumnAlternating
                          textCol={'8'}
                          imageCol={'4'}
                          title={sections.title}
                          subtitle={sections.subtitle}
                          subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                          description={sections.description}
                          imageUrl={sections.image.childImageSharp.fluid}
                          imageAltText={sections.alttext}
                          placement={sections.placement}
                          titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                          link={sections.btnlink}
                          colour={sections.btncolour}
                        />
                        {sections.popupimage && (
                          <ModalImage
                            blankCol={'4'}
                            buttonCol={'8'}
                            placement="right"
                            image={sections.popupimage.childImageSharp.fluid}
                            alttext={sections.popupalttext}
                          />
                        )}
                      </Fragment>
                    )
                  })}
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}

        <section className="bg-white">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <ColumnAlternatingButton
                textCol={'5'}
                imageCol={'7'}
                title={post.frontmatter.govagencies.title}
                subtitle={post.frontmatter.govagencies.subtitle}
                description={post.frontmatter.govagencies.description}
                imageUrl={ post.frontmatter.govagencies.image.childImageSharp.fluid }
                imageAltText={post.frontmatter.govagencies.alttext}
                placement={post.frontmatter.govagencies.placement}
                titleClass="font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium"
                link={post.frontmatter.govagencies.link}
                linktext={post.frontmatter.govagencies.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.helpfulresources && (
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.helpfulresources.title}
              </h2>
              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => {
                        return (
                          <Fragment key={index}>
                            <Card
                              collg="4"
                              colmd="6"
                              height="6.5rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              imageUrl={helpfulres.image.childImageSharp.fluid}
                              imageAltText={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                              titleclass="title-small"
                              descriptionClass="text-card-small"
                            />
                          </Fragment>
                        )
                      }
                    )}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}

        <section className="bg-blue-green-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }       
    markdownRemark(frontmatter: { name: { eq: "microsoft-azurestack" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug         
        openingpara
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          placement
          alttext
          btnlink
          btncolour
          popupimage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          popupalttext
        }
        security {
          title
          subtitle
          description
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        govagencies {
          title
          subtitle
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hybridmodel {
          title
          description
          linktext
          link
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 960, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`
